import React from 'react'
import Login from '../features/user/Login'
import SEO from "../components/SEO";
function LoginPage() {
  return (
    <div>
      <Login />
    </div>
  )
}

export default LoginPage