import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Container,
  Paper,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import AuthContext from "../../services/AuthContext";
import { useParams } from "react-router-dom";
import LoadingOverlay from "../../components/LoadingOverlay";

const OrderContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(4),
  backgroundColor: "#3f3b3f80",
  marginBottom: theme.spacing(10),
}));

const InfoBox = styled(Box)(({ theme }) => ({
  border: "3px solid grey",
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
}));

const OrderSummary = () => {
  const { Fetch } = useContext(AuthContext);
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await Fetch({ order_id: orderId }, "get_order");
        setOrderData(response?.data || {});
      } catch (error) {
        console.error("Error fetching order data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();

    return () => {
      setOrderData({});
    };
  }, [Fetch, orderId]);

  return (
    <Box sx={{ padding: { xs: 2, sm: 4 }, backgroundColor: "#ECE6D8" }}>
      <Container maxWidth="md">
        {loading && <LoadingOverlay />}
        <OrderContainer>
          <Typography variant="h5" gutterBottom sx={{ color: "#fff" }}>
            View Order Details
          </Typography>

          <InfoBox>
            <Typography gutterBottom sx={{ color: "#fff" }}>
              {`Order Date: ${orderData.created_at || "N/A"}`}
            </Typography>
            <Typography gutterBottom sx={{ color: "#fff" }}>
              {`Order #: ${orderData.id || "N/A"}`}
            </Typography>
            <Typography gutterBottom sx={{ color: "#fff" }}>
              {`Total Price: ₹${orderData.total_price || "N/A"}`}
            </Typography>
          </InfoBox>

          <Button variant="contained" color="error" sx={{ marginBottom: 2 }}>
            Cancel Order
          </Button>

          <Typography variant="h6" gutterBottom sx={{ color: "#fff" }}>
            Shipment Details
          </Typography>

          <InfoBox>
            <Typography gutterBottom sx={{ color: "#fff" }}>
              {orderData.customer_name || "N/A"}
            </Typography>
            <Typography gutterBottom sx={{ color: "#fff" }}>
              {orderData.address || "N/A"}
            </Typography>
            <Typography gutterBottom sx={{ color: "#fff" }}>
              {`${orderData.city || ""} ${orderData.state || ""} ${
                orderData.pincode || ""
              }`}
            </Typography>
            <Typography gutterBottom sx={{ color: "#fff" }}>
              {orderData.country || "N/A"}
            </Typography>
            <Typography gutterBottom sx={{ color: "#fff" }}>
              {orderData.phone || "N/A"}
            </Typography>
          </InfoBox>

          <Button variant="contained" color="primary">
            Track Shipment
          </Button>
        </OrderContainer>
      </Container>
    </Box>
  );
};

export default OrderSummary;
