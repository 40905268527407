import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Typography,
  Paper,
  Box,
  Divider,
  Grid,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import AuthContext from "../../services/AuthContext";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../../components/LoadingOverlay";

const OrderContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(4),
  backgroundColor: "#3f3b3f80",
}));

const InfoBox = styled(Box)(({ theme }) => ({
  border: "3px solid grey",
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
}));

const OrderDetails = () => {
  const [orders, setOrders] = useState([]);
  const { getData, ImageFetch } = useContext(AuthContext);
  const [orderData, setAddress] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // Helper to fetch order images
  const fetchImageUrl = async (imgPath) => {
    try {
      const imgResponse = await ImageFetch({ file_path: imgPath }, "get_file");
      return URL.createObjectURL(imgResponse);
    } catch (error) {
      console.error("Error fetching image:", error);
      return "";
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getData("list_order_by_userfilter");

        if (response && Array.isArray(response.data)) {
          const updatedProducts = await Promise.all(
            response.data.map(async (product) => {
              const productImage = Array.isArray(product.product_image)
                ? product.product_image
                : JSON.parse(product.product_image || "[]");
              const imageUrl = await fetchImageUrl(productImage[0] || "");

              return { ...product, imageUrl };
            })
          );

          setOrders(updatedProducts);
          setAddress(updatedProducts[0]);
        } else {
          console.warn("Response data is not an array:", response.data);
          setOrders([]);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [getData, ImageFetch]);

  const sortedOrders = orders.sort((a, b) => b.order_id - a.order_id);

  return (
    <Box sx={{ padding: { xs: 2, sm: 4 }, backgroundColor: "#ECE6D8" }}>
      <Container maxWidth="md">
        {loading && <LoadingOverlay />}
        <OrderContainer>
          <Typography variant="h5" gutterBottom sx={{ color: "#fff" }}>
            Order Details
          </Typography>

          <Divider sx={{ marginY: 2 }} />

          <Box sx={{ marginBottom: 2, backgroundColor: "white", borderRadius: 2, padding: 2 }}>
            {sortedOrders.length > 0 ? (
              sortedOrders.map((item) => (
                <Grid container alignItems="center" spacing={1} key={item.order_id}>
                  <Grid item xs={12} sm={4} md={3}>
                    <img
                      loading="lazy"
                      src={item.imageUrl}
                      alt={item.product_name}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <Typography variant="h6">{item.product_name}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={2} md={3}>
                    <Typography variant="h6">{item.status}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2}>
                    <Button
                      onClick={() => navigate(`/ordersummary/${item.order_id}`)}
                    >
                      View Order Details
                    </Button>
                  </Grid>
                  <Divider sx={{ width: "100%", marginY: 2, borderColor: "#bed4c2" }} />
                </Grid>
              ))
            ) : (
              <Typography
                variant="body1"
                sx={{ color: "black", textAlign: "center" }}
              >
                No orders to display
              </Typography>
            )}
          </Box>

          <Divider sx={{ marginY: 2 }} />

          <Typography variant="h6" gutterBottom sx={{ color: "#fff" }}>
            Shipping Address
          </Typography>



          {
            orderData?.address ? (


              <InfoBox>
                <Typography gutterBottom sx={{ color: "#fff" }}>
                  {orderData?.address || "N/A"}
                </Typography>
                <Typography gutterBottom sx={{ color: "#fff" }}>
                  {`${orderData?.city || ""} ${orderData.state || ""} ${orderData.pincode || ""
                    }`}
                </Typography>
                <Typography gutterBottom sx={{ color: "#fff" }}>
                  {orderData?.country || "N/A"}
                </Typography>
                <Typography gutterBottom sx={{ color: "#fff" }}>
                  {orderData?.phone || "N/A"}
                </Typography>
              </InfoBox>
            ) : (
              <Typography variant="body1" sx={{ color: "#fff" }}>
                N/A
              </Typography>
            )
          }
        </OrderContainer>
      </Container>
    </Box>
  );
};

export default OrderDetails;
