import React from "react";
import { Container, Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import "./policies.css";
const ProfileContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

function Terms() {
  const email = "info@thepurebliss.in";
  const phoneNumber = "+91 63474321199";
  // const googleMapsUrl =
  //   "https://www.google.com/maps/search/?api=1&query=Pure+bliss,+2/689+2nd+main+road,+Ranga+Reddy+Garden,+Neelankarai,+Chennai+-+600041,+Tamilnadu";

  return (
    <div className="profile-div">
      <Container maxWidth="md" className="profile_container">
        <ProfileContainer className="profile_container">
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              textTransform: "uppercase",
              padding: "20px",
              fontSize: "1rem",
              fontWeight: "600",
              letterSpacing: "2px",
              fontFamily: "pureblissPoppinsLight",
              textAlign: "center",
            }}
          >
            Terms & Conditions
          </Typography>

          <Typography sx={{ fontFamily: "pureblissPoppinsLight" }}>
            <p>
              Terms and Conditions of Use Welcome to The Pure Bliss! This
              document serves as an electronic record under the Information
              Technology Act, 2000, and related rules.{" "}
            </p>
            <p>
              This electronic record is generated by a computer system and does
              not require physical or digital signatures. This document is
              published in accordance with Rule 3 (1) of the Information
              Technology (Intermediaries Guidelines) Rules, 2011, which mandates
              the publication of rules, regulations, privacy policies, and Terms
              of Use for accessing and using our website{" "}
              <a
                href="https://thepurebliss.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://thepurebliss.in
              </a>
              . You agree to comply with and be bound by the following Terms and
              Conditions.{" "}
            </p>
            <p>Please read these Terms carefully before using the Site. </p>
            <p>
              The Platform is owned and operated by The Pure Bliss, with its
              registered office at 2/689, 2nd Main Rd, Ranga Reddy Gardens,
              Saraswathy Nagar North, Neelankarai, Chennai, Tamil Nadu 600041
              (hereinafter referred to as "The Pure Bliss").
            </p>
            <p>
              By accessing or using the Platform, you agree to comply with and
              be bound by the following Terms of Use . These Terms, together
              with our Privacy Policy and other applicable policies, govern your
              use of the Platform and any transactions made through it.{" "}
            </p>
            <Typography
              sx={{ fontFamily: "pureblissPoppinsLight", fontWeight: "600" }}
            >
              1. Acceptance of Terms
            </Typography>
            <p>
              Your use of the Platform indicates your acceptance of these Terms
              and our Privacy Policy.
            </p>
            <Typography
              sx={{ fontFamily: "pureblissPoppinsLight", fontWeight: "600" }}
            >
              2. Membership Eligibility
            </Typography>
            <p>
              The Platform is intended for use by individuals who can form
              legally binding contracts under applicable law. If you are under
              the age of 18, you may only use the Platform with the supervision
              and prior consent of a parent or legal guardian. Transactions may
              only be conducted by individuals who are legally capable of
              entering into binding contracts.
            </p>
            <Typography
              sx={{ fontFamily: "pureblissPoppinsLight", fontWeight: "600" }}
            >
              3. Account Registration and Security
            </Typography>
            <p>
              {" "}
              To access certain features of the Platform, you may need to create
              an account. You are responsible for maintaining the
              confidentiality of your account information, including your
              username and password. You are liable for all activities that
              occur under your account. If you provide false, inaccurate, or
              incomplete information, or if we have reasonable grounds to
              suspect such information, we may suspend or terminate your
              account. You must keep your contact information, including your
              mobile phone number and email address, up to date. Notify us
              immediately of any changes to your contact details by updating
              them on the Platform.
            </p>
            <Typography
              sx={{ fontFamily: "pureblissPoppinsLight", fontWeight: "600" }}
            >
              4. User Responsibilities
            </Typography>
            <p>
              You agree to ensure that you log out from your account at the end
              of each session. Notify The Pure Bliss immediately of any
              unauthorized use of your account. Maintain the confidentiality of
              your account information and not share your login details with
              others.
            </p>
            <Typography
              sx={{ fontFamily: "pureblissPoppinsLight", fontWeight: "600" }}
            >
              5. Communication By using the Platform
            </Typography>
            <p>
              You consent to receive electronic communications from us. We may
              communicate with you via email or other electronic means. It is
              your responsibility to ensure that your contact information is
              accurate and up to date.
            </p>
            <Typography
              sx={{ fontFamily: "pureblissPoppinsLight", fontWeight: "600" }}
            >
              6. Modifications to Terms
            </Typography>
            <p>
              We may update these Terms from time to time. Changes will be
              posted on this page, and your continued use of the Platform after
              such changes constitutes your acceptance of the new Terms.
            </p>
            <Typography
              sx={{ fontFamily: "pureblissPoppinsLight", fontWeight: "600" }}
            >
              7. Limitation of Liability
            </Typography>
            <p>
              {" "}
              The Pure Bliss shall not be liable for any loss or damage
              resulting from unauthorized access to or use of your account. We
              are not responsible for any issues arising from your failure to
              maintain the confidentiality of your account information.
            </p>
            {/* <Typography
              sx={{ fontFamily: "pureblissPoppinsLight", fontWeight: "600" }}
            >
              8. Governing Law
            </Typography>
            
            These Terms are governed by the laws of [Your Jurisdiction]. Any
            disputes arising under or in connection with these Terms will be
            subject to the exclusive jurisdiction of the courts in [Your
            Jurisdiction]. */}
            <Typography
              sx={{ fontFamily: "pureblissPoppinsLight", fontWeight: "600" }}
            >
              8. Contact Information
            </Typography>
            <p>
              {" "}
              For any questions or concerns regarding these Terms or your use of
              the Platform, please contact us at
            </p>
            <p>
              Email:
              <a
                href={`mailto:${email}`}
                // style={{ textDecoration: "none", color: "inherit" }}
              >
                {email}
              </a>
            </p>
            <p>
              {" "}
              Phone:{" "}
              <a
                href={`tel:${phoneNumber}`}
                // style={{ textDecoration: "none", color: "inherit" }}
              >
                {" "}
                {phoneNumber}{" "}
              </a>
            </p>
            By using the Platform, you acknowledge that you have read,
            understood, and agree to these Terms.
          </Typography>
        </ProfileContainer>
      </Container>
    </div>
  );
}

export default Terms;
